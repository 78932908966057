/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CheckIcon from "@material-ui/icons/Check";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card, CardContent } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageContent } from "../../../shared/components/page-content";
import { PageHeader } from "../../../shared/components/page-header";
import { AlreadyAppliedToEmployerModal } from "./already-applied-to-employer-modal";
import { ApplyToEmployerModal } from "./apply-to-employer-modal";
import { useEmployerPageDataQuery } from "./employer-page-data.generated";

export const EmployerPage = () => {
  const [alreadyAppliedModalOpen, setAlreadyAppliedModalOpen] = useState(false);
  const [
    sendExistingApplicationModalOpen,
    setSendExistingApplicationModalOpen,
  ] = useState(false);
  const navigate = useNavigate();
  const { id: employerId } = useParams<"id">();
  const query = useEmployerPageDataQuery({
    variables: {
      employerId: employerId!,
    },
  });

  const content = (() => {
    if (query.error) {
      return <ErrorPage />;
    }

    if (!query.data) {
      return <PageLoadingState />;
    }

    if (!query.data.getSchoolsEmployerById) {
      return (
        <Alert message="This employer could not be found" severity="info" />
      );
    }

    const employer = query.data.getSchoolsEmployerById;
    const student = query.data.getMySchoolsStudent;

    return (
      <>
        <AlreadyAppliedToEmployerModal
          open={alreadyAppliedModalOpen}
          onClose={() => {
            setAlreadyAppliedModalOpen(false);
          }}
        />

        <ApplyToEmployerModal
          open={sendExistingApplicationModalOpen}
          employerId={employerId!}
          onClose={() => {
            setSendExistingApplicationModalOpen(false);
          }}
          onApplied={() => {
            query.refetch();
          }}
        />

        <Button
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
          color="primary"
          size="large"
          onClick={() => {
            if (employer.applied) {
              setAlreadyAppliedModalOpen(true);
            } else if (student.createdApplication) {
              setSendExistingApplicationModalOpen(true);
            } else {
              navigate("/apply");
            }
          }}
          endIcon={employer.applied ? <CheckIcon /> : undefined}
          label={employer.applied ? "Applied" : "Apply"}
        />

        <Card variant="flat">
          <CardContent>
            <Typography variant="body">
              <div
                dangerouslySetInnerHTML={{
                  __html: employer.profileHTML,
                }}
              />
            </Typography>
          </CardContent>
        </Card>
      </>
    );
  })();

  return (
    <>
      <PageHeader
        onBackClicked={() => {
          navigate(-1);
        }}
        title={query.data?.getSchoolsEmployerById?.name ?? ""}
      />
      <PageContent>{content}</PageContent>
    </>
  );
};
