import { createContext, ReactNode, useContext } from "react";

import { ApplyBioFormValues } from "./steps/bio";
import { ApplyLocationFormValues } from "./steps/location";
import { ApplyWorkFormValues } from "./steps/work";

export type ApplyWizardFormValues = Partial<
  ApplyBioFormValues & ApplyLocationFormValues & ApplyWorkFormValues
>;

export type ApplyWizardStep = "bio" | "location" | "work";

interface ApplyWizardContextValue {
  currentStep: ApplyWizardStep;
  goToStep(step: ApplyWizardStep): void;
  formValues: ApplyWizardFormValues;
  updateFormValues(values: ApplyWizardFormValues): void;
}

const ApplyWizardContext = createContext<ApplyWizardContextValue>({
  currentStep: "bio",
  goToStep: () => {},
  formValues: {},
  updateFormValues: () => {},
});

interface ApplyWizardContextProviderProps {
  currentStep: ApplyWizardStep;
  goToStep(step: ApplyWizardStep): void;
  formValues: ApplyWizardFormValues;
  updateFormValues(values: ApplyWizardFormValues): void;
  children: ReactNode;
}

export const ApplyWizardContextProvider = ({
  currentStep,
  goToStep,
  formValues,
  updateFormValues,
  children,
}: ApplyWizardContextProviderProps) => {
  return (
    <ApplyWizardContext.Provider
      value={{
        currentStep,
        goToStep,
        formValues,
        updateFormValues,
      }}
    >
      {children}
    </ApplyWizardContext.Provider>
  );
};

export function useApplyWizard() {
  const { goToStep, formValues, updateFormValues } = useContext(
    ApplyWizardContext
  );

  return { goToStep, formValues, updateFormValues };
}
