import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ApplyToEmployerMutationVariables = Types.Exact<{
  employerId: Types.Scalars['ID'];
}>;


export type ApplyToEmployerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'applyToSchoolsEmployer'>
);


export const ApplyToEmployerDocument = gql`
    mutation ApplyToEmployer($employerId: ID!) {
  applyToSchoolsEmployer(employerId: $employerId)
}
    `;
export type ApplyToEmployerMutationFn = Apollo.MutationFunction<ApplyToEmployerMutation, ApplyToEmployerMutationVariables>;

/**
 * __useApplyToEmployerMutation__
 *
 * To run a mutation, you first call `useApplyToEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyToEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyToEmployerMutation, { data, loading, error }] = useApplyToEmployerMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useApplyToEmployerMutation(baseOptions?: Apollo.MutationHookOptions<ApplyToEmployerMutation, ApplyToEmployerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyToEmployerMutation, ApplyToEmployerMutationVariables>(ApplyToEmployerDocument, options);
      }
export type ApplyToEmployerMutationHookResult = ReturnType<typeof useApplyToEmployerMutation>;
export type ApplyToEmployerMutationResult = Apollo.MutationResult<ApplyToEmployerMutation>;
export type ApplyToEmployerMutationOptions = Apollo.BaseMutationOptions<ApplyToEmployerMutation, ApplyToEmployerMutationVariables>;