import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateApplicationMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  email: Types.Scalars['String'];
  preferredName?: Types.Maybe<Types.Scalars['String']>;
  phoneNumber: Types.Scalars['String'];
  homePostalCode: Types.Scalars['String'];
  travelCapability: Types.SchoolsJobApplicationTravelCapability;
  previousEmployers: Array<Types.Scalars['String']> | Types.Scalars['String'];
  desiredHours: Types.SchoolsJobApplicationDesiredHours;
  desiredShiftTimesOfDay: Array<Types.SchoolsJobApplicationDesiredShiftTimeOfDay> | Types.SchoolsJobApplicationDesiredShiftTimeOfDay;
  desiredShiftDaysOfWeek: Array<Types.SchoolsJobApplicationDesiredShiftDaysOfWeek> | Types.SchoolsJobApplicationDesiredShiftDaysOfWeek;
}>;


export type CreateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { createSchoolsStudentApplication: (
    { __typename?: 'SchoolsJobApplication' }
    & Pick<Types.SchoolsJobApplication, 'id'>
  ) }
);


export const CreateApplicationDocument = gql`
    mutation CreateApplication($firstName: String!, $lastName: String!, $email: String!, $preferredName: String, $phoneNumber: String!, $homePostalCode: String!, $travelCapability: SchoolsJobApplicationTravelCapability!, $previousEmployers: [String!]!, $desiredHours: SchoolsJobApplicationDesiredHours!, $desiredShiftTimesOfDay: [SchoolsJobApplicationDesiredShiftTimeOfDay!]!, $desiredShiftDaysOfWeek: [SchoolsJobApplicationDesiredShiftDaysOfWeek!]!) {
  createSchoolsStudentApplication(
    firstName: $firstName
    lastName: $lastName
    email: $email
    preferredName: $preferredName
    phoneNumber: $phoneNumber
    homePostalCode: $homePostalCode
    travelCapability: $travelCapability
    previousEmployers: $previousEmployers
    desiredHours: $desiredHours
    desiredShiftTimesOfDay: $desiredShiftTimesOfDay
    desiredShiftDaysOfWeek: $desiredShiftDaysOfWeek
  ) {
    id
  }
}
    `;
export type CreateApplicationMutationFn = Apollo.MutationFunction<CreateApplicationMutation, CreateApplicationMutationVariables>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      preferredName: // value for 'preferredName'
 *      phoneNumber: // value for 'phoneNumber'
 *      homePostalCode: // value for 'homePostalCode'
 *      travelCapability: // value for 'travelCapability'
 *      previousEmployers: // value for 'previousEmployers'
 *      desiredHours: // value for 'desiredHours'
 *      desiredShiftTimesOfDay: // value for 'desiredShiftTimesOfDay'
 *      desiredShiftDaysOfWeek: // value for 'desiredShiftDaysOfWeek'
 *   },
 * });
 */
export function useCreateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(CreateApplicationDocument, options);
      }
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = Apollo.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<CreateApplicationMutation, CreateApplicationMutationVariables>;