import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EmployerPageDataQueryVariables = Types.Exact<{
  employerId: Types.Scalars['ID'];
}>;


export type EmployerPageDataQuery = (
  { __typename?: 'Query' }
  & { getSchoolsEmployerById?: Types.Maybe<(
    { __typename?: 'SchoolsEmployer' }
    & Pick<Types.SchoolsEmployer, 'id' | 'name' | 'profileHTML' | 'applied'>
  )>, getMySchoolsStudent: (
    { __typename?: 'SchoolsStudent' }
    & Pick<Types.SchoolsStudent, 'id' | 'createdApplication'>
  ) }
);


export const EmployerPageDataDocument = gql`
    query EmployerPageData($employerId: ID!) {
  getSchoolsEmployerById(id: $employerId) {
    id
    name
    profileHTML
    applied
  }
  getMySchoolsStudent {
    id
    createdApplication
  }
}
    `;

/**
 * __useEmployerPageDataQuery__
 *
 * To run a query within a React component, call `useEmployerPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerPageDataQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useEmployerPageDataQuery(baseOptions: Apollo.QueryHookOptions<EmployerPageDataQuery, EmployerPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployerPageDataQuery, EmployerPageDataQueryVariables>(EmployerPageDataDocument, options);
      }
export function useEmployerPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployerPageDataQuery, EmployerPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployerPageDataQuery, EmployerPageDataQueryVariables>(EmployerPageDataDocument, options);
        }
export type EmployerPageDataQueryHookResult = ReturnType<typeof useEmployerPageDataQuery>;
export type EmployerPageDataLazyQueryHookResult = ReturnType<typeof useEmployerPageDataLazyQuery>;
export type EmployerPageDataQueryResult = Apollo.QueryResult<EmployerPageDataQuery, EmployerPageDataQueryVariables>;