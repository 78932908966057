import { useApolloClient } from "@apollo/client";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { decodeJWT } from "@rewards-web/shared/lib/decode-jwt";
import {
  useSetAnalyticsIdentity,
  useTrack,
} from "@rewards-web/shared/modules/analytics";
import {
  reportError,
  useSetErrorTrackingIdentity,
} from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import {
  AuthenticateStudentLoginTokenDocument,
  AuthenticateStudentLoginTokenMutation,
} from "./authenticate-student-login-token.generated";

export function isAuthenticated() {
  return !!getAccessToken();
}

export function getAccessToken() {
  return localStorage.getItem(STORAGE_KEY);
}

function saveAccessToken(accessToken: string) {
  localStorage.setItem(STORAGE_KEY, accessToken);
}

const STORAGE_KEY = "access_token";

interface SchoolsAuthContextValue {
  accessToken: string | null;
  onSignedIn(accessToken: string): void;
}

const SchoolsAuthContext = createContext<SchoolsAuthContextValue>({
  accessToken: null,
  onSignedIn: () => {},
});

interface SchoolsAuthProviderProps {
  children: ReactNode;
}

export const SchoolsAuthProvider = ({ children }: SchoolsAuthProviderProps) => {
  const setAnalyticsIdentity = useSetAnalyticsIdentity();
  const setErrorTrackingIdentity = useSetErrorTrackingIdentity();
  const [initialized, setInitialized] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(() => null);
  const track = useTrack();
  const snackbar = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const phoneNumber = useMemo(() => searchParams.get("loginPhoneNumber"), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loginTokenId = useMemo(() => searchParams.get("loginTokenId"), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loginToken = useMemo(() => searchParams.get("loginToken"), []);

  const hasLoginParams = Boolean(phoneNumber && loginTokenId && loginToken);

  const client = useApolloClient();

  const handleSignedIn = useCallback(
    (accessToken: string) => {
      setAccessToken(accessToken);

      const jwt = decodeJWT(accessToken);

      setAnalyticsIdentity(jwt.user_id);
      setErrorTrackingIdentity(jwt.user_id);

      try {
        saveAccessToken(accessToken);
      } catch (error) {
        reportError(
          `Could not save token to storage: ${(error as any)?.message}`
        );
      }
    },
    [setAnalyticsIdentity, setErrorTrackingIdentity]
  );

  const prevAccessToken = getAccessToken();

  const initialize = async () => {
    // clear from URL
    const nextSearchParams = new URLSearchParams(searchParams);
    nextSearchParams.delete("loginPhoneNumber");
    nextSearchParams.delete("loginTokenId");
    nextSearchParams.delete("loginToken");

    if (Array.from(nextSearchParams.entries()).length === 0) {
      // strip "?" from URL if there are no query params
      navigate(location.pathname, { replace: true });
    } else {
      setSearchParams(nextSearchParams, { replace: true });
    }

    if (hasLoginParams) {
      // TODO consider logging out of a current session to prevent concurrent active sessions

      try {
        const data = await client.mutate<AuthenticateStudentLoginTokenMutation>(
          {
            mutation: AuthenticateStudentLoginTokenDocument,
            variables: {
              phoneNumber,
              loginTokenId,
              loginToken,
            },
            fetchPolicy: "no-cache",
          }
        );

        const accessToken = data.data!.authenticateSchoolsStudentLoginToken
          .accessToken;

        handleSignedIn(accessToken);
        track("Logged in via token", { loginTokenId });
      } catch (error) {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An error occurred logging you in. Please try again later.",
        });
      }
    } else if (prevAccessToken) {
      handleSignedIn(prevAccessToken);
    }

    setInitialized(true);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialized) {
    return <PageLoadingState label="Logging in..." />;
  }

  return (
    <SchoolsAuthContext.Provider
      value={{ accessToken, onSignedIn: handleSignedIn }}
    >
      {children}
    </SchoolsAuthContext.Provider>
  );
};

export function useSchoolsAuth() {
  const { accessToken, onSignedIn } = useContext(SchoolsAuthContext);
  return { accessToken, signedIn: !!accessToken, onSignedIn };
}
