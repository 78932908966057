import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsHomePageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SchoolsHomePageDataQuery = (
  { __typename?: 'Query' }
  & { listSchoolsEmployerSurveys: (
    { __typename?: 'ListSchoolsEmployerSurveysResponse' }
    & { items: Array<(
      { __typename?: 'SchoolsEmployerSurvey' }
      & Pick<Types.SchoolsEmployerSurvey, 'id' | 'question'>
      & { results: Array<(
        { __typename?: 'SchoolsEmployerSurveyResult' }
        & Pick<Types.SchoolsEmployerSurveyResult, 'id'>
      )> }
    )> }
  ) }
);


export const SchoolsHomePageDataDocument = gql`
    query SchoolsHomePageData {
  listSchoolsEmployerSurveys {
    items {
      id
      question
      results {
        id
      }
    }
  }
}
    `;

/**
 * __useSchoolsHomePageDataQuery__
 *
 * To run a query within a React component, call `useSchoolsHomePageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsHomePageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsHomePageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolsHomePageDataQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsHomePageDataQuery, SchoolsHomePageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsHomePageDataQuery, SchoolsHomePageDataQueryVariables>(SchoolsHomePageDataDocument, options);
      }
export function useSchoolsHomePageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsHomePageDataQuery, SchoolsHomePageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsHomePageDataQuery, SchoolsHomePageDataQueryVariables>(SchoolsHomePageDataDocument, options);
        }
export type SchoolsHomePageDataQueryHookResult = ReturnType<typeof useSchoolsHomePageDataQuery>;
export type SchoolsHomePageDataLazyQueryHookResult = ReturnType<typeof useSchoolsHomePageDataLazyQuery>;
export type SchoolsHomePageDataQueryResult = Apollo.QueryResult<SchoolsHomePageDataQuery, SchoolsHomePageDataQueryVariables>;