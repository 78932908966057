import { darken, lighten } from "@material-ui/core";

import { AppThemeProperties } from "@rewards-web/shared/style/theme";
import { DeepPartial } from "@rewards-web/shared/types";

const primaryColor = "#0A6F58";

export const applyStepTheme: DeepPartial<AppThemeProperties> = {
  palette: {
    primary: {
      main: primaryColor,
      dark: darken(primaryColor!, 0.3),
      light: lighten(primaryColor!, 0.3),
    },
  },
};
