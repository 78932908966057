/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface SurveyResultProgressProps {
  results: Array<{
    percentage: number;
    answer: string;
  }>;
}

export const SurveyResultProgress = ({
  results,
}: SurveyResultProgressProps) => {
  const theme = useTheme();

  const colorOptions: Array<{
    textColor: string;
    backgroundColor: string;
  }> = [
    { textColor: "#fff", backgroundColor: theme.palette.primary.main },
    { textColor: "#000", backgroundColor: "rgba(251, 231, 236, 0.5)" },
  ];

  return (
    <div
      aria-label="Survey results"
      css={css`
        position: relative;
        width: 100%;
        height: 24px;
      `}
    >
      {results.map(({ answer, percentage }, index) => {
        const isFirstItem = index === 0;
        const isLastItem = index === results.length - 1;
        const previousItem = results[index - 1];
        const { textColor, backgroundColor } = colorOptions[
          index % colorOptions.length
        ];

        return (
          <div
            key={answer}
            arial-label={`${answer}: ${percentage}%`}
            css={(theme: AppTheme) => css`
              position: absolute;
              height: 100%;
              width: ${percentage}%;
              background-color: ${backgroundColor};
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 ${theme.spacing(1)};

              ${isFirstItem &&
              css`
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
              `}

              ${isLastItem &&
              css`
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
              `}

              ${!isFirstItem &&
              css`
                left: ${previousItem!.percentage}%;
              `}
            `}
          >
            <Typography
              css={css`
                font-weight: 600;
                color: ${textColor};
                text-transform: uppercase;
                font-size: 0.7rem;
              `}
              variant="caption"
            >
              {answer}
            </Typography>
            <Typography
              css={css`
                font-weight: 600;
                color: ${textColor};
                text-transform: uppercase;
                font-size: 0.7rem;
              `}
              variant="caption"
            >
              {percentage}%
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
