/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageContent } from "../../../shared/components/page-content";
import { PageHeader } from "../../../shared/components/page-header";
import { SurveyCard } from "../../../shared/components/survey-card";
import { SurveyResultCard } from "./survey-result-card";
import { useSurveyResultsQuery } from "./survey-results.generated";

export const SurveyResultsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<"id">();

  const surveyResultsQuery = useSurveyResultsQuery({
    variables: {
      surveyId: id!,
    },
    onError: reportError,
  });

  const content = (() => {
    if (surveyResultsQuery.error) {
      return <ErrorPage />;
    }

    if (!surveyResultsQuery.data) {
      return <PageLoadingState />;
    }

    if (!surveyResultsQuery.data.getSchoolsEmployerSurveyById) {
      // the survey no longer exists
      return <Alert message="This survey no longer exists" severity="info" />;
    }

    const {
      data: { getSchoolsEmployerSurveyById: survey },
    } = surveyResultsQuery;

    return (
      <div>
        <SurveyCard
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
          data={survey}
        />
        {survey.results.map((result) => (
          <SurveyResultCard
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
            data={result}
          />
        ))}
      </div>
    );
  })();

  return (
    <>
      <PageHeader
        onBackClicked={() => {
          navigate("/");
        }}
        title="Survey Results"
      />
      <PageContent>{content}</PageContent>
    </>
  );
};
