import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { isEmail } from "@rewards-web/shared/lib/is-email";
import { isPhoneNumber } from "@rewards-web/shared/lib/is-phone-number";
import { stripNonNumericCharacters } from "@rewards-web/shared/lib/strip-non-numeric-characters";
import { AppThemeProvider } from "@rewards-web/shared/style/theme";

import { PageContent } from "../../../../../shared/components/page-content";
import { PageHeader } from "../../../../../shared/components/page-header";
import { useApplyWizard } from "../../wizard-form-context";
import { applyStepTheme } from "../apply-step-theme";
import headerImageUrl from "./apply-header.png";

export interface ApplyBioFormValues {
  firstName: string;
  lastName: string;
  email: string;
  preferredName: string;
  phoneNumber: string;
}

export const ApplyBioPage = () => {
  const { formValues, updateFormValues, goToStep } = useApplyWizard();
  const form = useForm<ApplyBioFormValues>({
    defaultValues: {
      firstName: formValues.firstName ?? "",
      lastName: formValues.lastName ?? "",
      email: formValues.email ?? "",
      preferredName: formValues.preferredName ?? "",
      phoneNumber: formValues.phoneNumber ?? "",
    },
  });

  const handleSubmit = (values: ApplyBioFormValues) => {
    updateFormValues(values);
    goToStep("location");
  };

  return (
    <>
      <AppThemeProvider theme={applyStepTheme}>
        <PageHeader
          title="Early Application"
          backgroundColor="primary"
          description="Fill out your contact info so the agency can schedule an interview"
          image={{
            url: headerImageUrl,
            alt: "Early Application",
          }}
        />
      </AppThemeProvider>
      <PageContent>
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <AppThemeProvider theme={applyStepTheme}>
            <TextField
              {...form.register("firstName", {
                required: "First Name is required",
              })}
              error={form.formState.errors.firstName}
              label="First Name"
            />
            <TextField
              {...form.register("lastName", {
                required: "Last Name is required",
              })}
              error={form.formState.errors.lastName}
              label="Last Name"
            />
            <TextField
              {...form.register("email", {
                required: "Email is required",
                validate: (value) => {
                  if (!isEmail(value)) {
                    return "Please enter a valid email address";
                  }
                },
              })}
              error={form.formState.errors.email}
              label="Email"
              type="email"
            />
            <TextField
              {...form.register("preferredName")}
              error={form.formState.errors.preferredName}
              label="Preferred Name (Optional)"
            />
            <TextField
              {...form.register("phoneNumber", {
                required: "Phone Number is required",
                validate: (value) => {
                  if (
                    value &&
                    !isPhoneNumber(stripNonNumericCharacters(value))
                  ) {
                    return "Please enter a valid cell phone number";
                  }
                },
              })}
              error={form.formState.errors.phoneNumber}
              label="Phone Number"
              type="tel"
            />
          </AppThemeProvider>
          <Button
            color="primary"
            size="large"
            label="Continue"
            type="submit"
            endIcon={<ChevronRightIcon />}
          />
        </Form>
      </PageContent>
    </>
  );
};
