/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AddIcon from "@material-ui/icons/Add";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { Form } from "@rewards-web/shared/components/form";
import { RadioButton } from "@rewards-web/shared/components/radio-buttons";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { SchoolsJobApplicationTravelCapability } from "@rewards-web/shared/graphql-types";
import { isPostalcode } from "@rewards-web/shared/lib/is-postal-code";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { AppThemeProvider } from "@rewards-web/shared/style/theme";

import { PageContent } from "../../../../../shared/components/page-content";
import { PageHeader } from "../../../../../shared/components/page-header";
import { useApplyWizard } from "../../wizard-form-context";
import { applyStepTheme } from "../apply-step-theme";

export interface ApplyLocationFormValues {
  homePostalCode: string;
  travelCapability: SchoolsJobApplicationTravelCapability | null;
  previousEmployers: Array<{ name: string }>;
}

export const ApplyLocationPage = () => {
  const { formValues, updateFormValues, goToStep } = useApplyWizard();

  const form = useForm<ApplyLocationFormValues>({
    defaultValues: {
      homePostalCode: formValues.homePostalCode ?? "",
      travelCapability: formValues.travelCapability ?? null,
      previousEmployers: formValues.previousEmployers ?? [{ name: "" }],
    },
  });

  const handleSubmit = (values: ApplyLocationFormValues) => {
    updateFormValues(values);
    goToStep("work");
  };

  const employerFields = useFieldArray({
    name: "previousEmployers",
    control: form.control,
  });

  return (
    <>
      <AppThemeProvider theme={applyStepTheme}>
        <PageHeader
          title="Early Application"
          backgroundColor="primary"
          description="Fill out your contact info so the agency can schedule an interview"
        />
      </AppThemeProvider>
      <PageContent>
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <AppThemeProvider theme={applyStepTheme}>
            <Typography
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(3)};
              `}
              variant="h2"
              color="textPrimary"
            >
              Location
            </Typography>
            <TextField
              label="Home postal code"
              placeholder="e.g. A1A 1A1"
              error={form.formState.errors.homePostalCode}
              {...form.register("homePostalCode", {
                required: "Home Postal code is required",
                validate: (value) => {
                  if (!isPostalcode(value)) {
                    return "Please enter a valid postal code";
                  }
                },
              })}
            />

            <Controller
              control={form.control}
              name="travelCapability"
              rules={{ required: "This field is required" }}
              render={({ field, fieldState }) => (
                <RadioGroup
                  ariaLabel="Travel Capability"
                  error={fieldState.error}
                  {...field}
                >
                  <RadioButton
                    value={
                      SchoolsJobApplicationTravelCapability.PublicTransitOnly
                    }
                    label="Public transit"
                  />
                  <RadioButton
                    value={SchoolsJobApplicationTravelCapability.AbleToDrive}
                    label="Willing to drive"
                  />
                </RadioGroup>
              )}
            />

            <Divider
              css={(theme: AppTheme) => css`
                margin: ${theme.spacing(2)} 0;
              `}
            />

            <Typography variant="h2" color="textPrimary" gutterBottom>
              Experience
            </Typography>
            <Typography
              variant="body"
              color="textPrimary"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(3)};
              `}
            >
              List your previous employers
            </Typography>

            <div
              css={(theme: AppTheme) => css`
                padding-bottom: ${theme.spacing(5)};
              `}
            >
              {employerFields.fields.map((field, index) => (
                <TextField
                  key={field.id}
                  label={`Employer ${index + 1}`}
                  hideSpaceForErrorText
                  {...form.register(`previousEmployers.${index}.name`)}
                />
              ))}
              <Typography
                component="button"
                variant="body"
                css={(theme: AppTheme) => css`
                  float: right;
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  font-size: 1.2em;

                  padding: 0;
                  background: none;
                  border: none;
                  text-align: left;
                  color: inherit;
                  cursor: pointer;

                  &,
                  & svg {
                    color: ${theme.palette.primary.dark};
                  }

                  svg {
                    font-size: 0.9em;
                  }
                `}
                onClick={() => employerFields.append({ name: "" })}
                type="button"
              >
                <AddIcon /> Add more
              </Typography>
            </div>
          </AppThemeProvider>

          <Button
            color="primary"
            size="large"
            label="Continue"
            type="submit"
            endIcon={<ChevronRightIcon />}
          />
        </Form>
      </PageContent>
    </>
  );
};
