/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { CheckboxField } from "@rewards-web/shared/components/checkbox-field";
import { Form } from "@rewards-web/shared/components/form";
import { RadioButton } from "@rewards-web/shared/components/radio-buttons";
import { RadioGroup } from "@rewards-web/shared/components/radio-buttons/radio-group";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  SchoolsJobApplicationDesiredHours,
  SchoolsJobApplicationDesiredShiftDaysOfWeek,
  SchoolsJobApplicationDesiredShiftTimeOfDay,
  SchoolsJobApplicationTravelCapability,
} from "@rewards-web/shared/graphql-types";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { AppThemeProvider } from "@rewards-web/shared/style/theme";

import { PageContent } from "../../../../../shared/components/page-content";
import { PageHeader } from "../../../../../shared/components/page-header";
import { useApplyToEmployerMutation } from "../../apply-to-employer.generated";
import { useCreateApplicationMutation } from "../../create-application.generated";
import {
  ApplyWizardFormValues,
  useApplyWizard,
} from "../../wizard-form-context";
import { applyStepTheme } from "../apply-step-theme";

export interface ApplyWorkFormValues {
  desiredHours: SchoolsJobApplicationDesiredHours | null;
  desiredShiftTimesOfDay: {
    [SchoolsJobApplicationDesiredShiftTimeOfDay.Mornings]: boolean;
    [SchoolsJobApplicationDesiredShiftTimeOfDay.WorkDays]: boolean;
    [SchoolsJobApplicationDesiredShiftTimeOfDay.Evenings]: boolean;
    [SchoolsJobApplicationDesiredShiftTimeOfDay.Nights]: boolean;
  };
  desiredShiftDaysOfWeek: {
    [SchoolsJobApplicationDesiredShiftDaysOfWeek.MondayToFriday]: boolean;
    [SchoolsJobApplicationDesiredShiftDaysOfWeek.WeekendsOnly]: boolean;
  };
}

export const ApplyWorkPage = () => {
  const track = useTrack();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { formValues, updateFormValues } = useApplyWizard();
  const [createApplication] = useCreateApplicationMutation();
  const [applyToEmployer] = useApplyToEmployerMutation();
  const [employerId] = useQueryParam("employer");

  const form = useForm<ApplyWorkFormValues>({
    defaultValues: {
      desiredHours: null,
      desiredShiftTimesOfDay: formValues.desiredShiftTimesOfDay ?? {
        [SchoolsJobApplicationDesiredShiftTimeOfDay.Mornings]: false,
        [SchoolsJobApplicationDesiredShiftTimeOfDay.WorkDays]: false,
        [SchoolsJobApplicationDesiredShiftTimeOfDay.Evenings]: false,
        [SchoolsJobApplicationDesiredShiftTimeOfDay.Nights]: false,
      },
      desiredShiftDaysOfWeek: formValues.desiredShiftDaysOfWeek ?? {
        [SchoolsJobApplicationDesiredShiftDaysOfWeek.MondayToFriday]: false,
        [SchoolsJobApplicationDesiredShiftDaysOfWeek.WeekendsOnly]: false,
      },
    },
  });

  const handleSubmit = async (values: ApplyWorkFormValues) => {
    updateFormValues(values);

    const allValues = {
      ...formValues,
      ...values,
    } as Required<ApplyWizardFormValues>;

    try {
      const res = await createApplication({
        variables: {
          firstName: allValues.firstName,
          lastName: allValues.lastName,
          email: allValues.email,
          preferredName: allValues.preferredName,
          phoneNumber: serializePhoneNumber(allValues.phoneNumber),
          homePostalCode: allValues.homePostalCode,
          travelCapability: allValues.travelCapability as SchoolsJobApplicationTravelCapability,
          previousEmployers: allValues.previousEmployers.map(
            ({ name }) => name
          ),
          desiredHours: allValues.desiredHours!,
          desiredShiftTimesOfDay: Object.entries(
            allValues.desiredShiftTimesOfDay
          )
            .filter(([_, checked]) => checked)
            .map(([key]) => key as SchoolsJobApplicationDesiredShiftTimeOfDay),
          desiredShiftDaysOfWeek: Object.entries(
            allValues.desiredShiftDaysOfWeek
          )
            .filter(([_, checked]) => checked)
            .map(([key]) => key as SchoolsJobApplicationDesiredShiftDaysOfWeek),
        },
      });

      if (employerId) {
        await applyToEmployer({ variables: { employerId } });
      }

      navigate(-1);

      track("Application created", {
        applicationId: res.data?.createSchoolsStudentApplication.id,
      });

      snackbar.show({
        severity: "success",
        message: "Your application has been submitted!",
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return (
    <>
      <AppThemeProvider theme={applyStepTheme}>
        <PageHeader
          title="Early Application"
          backgroundColor="primary"
          description="Fill out your contact info so the agency can schedule an interview"
        />
      </AppThemeProvider>
      <PageContent>
        <Form
          submitting={form.formState.isSubmitting}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <AppThemeProvider theme={applyStepTheme}>
            <Typography
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(1)};
              `}
              variant="h2"
              color="textPrimary"
            >
              Work
            </Typography>

            <Typography
              variant="h3"
              color="textPrimary"
              css={(theme: AppTheme) => css`
                border-bottom: 1px solid ${theme.palette.divider};
                font-size: 1.2em;
                padding-bottom: ${theme.spacing(1)};
              `}
            >
              What are your desired hours?
            </Typography>

            <div
              css={(theme: AppTheme) => css`
                padding: ${theme.spacing(2)};
              `}
            >
              <Controller
                control={form.control}
                name="desiredHours"
                rules={{ required: "This field is required" }}
                render={({ field, fieldState }) => (
                  <RadioGroup
                    ariaLabel="Desired Hours"
                    error={fieldState.error}
                    {...field}
                  >
                    <RadioButton
                      value={SchoolsJobApplicationDesiredHours.FullTime}
                      label="Full time"
                    />
                    <RadioButton
                      value={SchoolsJobApplicationDesiredHours.PartTime}
                      label="Part time"
                    />
                  </RadioGroup>
                )}
              />
            </div>

            <Typography
              variant="h3"
              color="textPrimary"
              css={(theme: AppTheme) => css`
                border-bottom: 1px solid ${theme.palette.divider};
                font-size: 1.2em;
                padding-bottom: ${theme.spacing(1)};
              `}
            >
              Which shifts are you willing to take?
            </Typography>

            <div
              css={(theme: AppTheme) => css`
                padding: ${theme.spacing(2)};
              `}
            >
              <Controller
                control={form.control}
                name={`desiredShiftTimesOfDay.${SchoolsJobApplicationDesiredShiftTimeOfDay.Mornings}`}
                render={({ field, fieldState }) => (
                  <CheckboxField
                    {...field}
                    error={fieldState.error}
                    label="Mornings"
                    display="block"
                  />
                )}
              />

              <Controller
                control={form.control}
                name={`desiredShiftTimesOfDay.${SchoolsJobApplicationDesiredShiftTimeOfDay.WorkDays}`}
                render={({ field, fieldState }) => (
                  <CheckboxField
                    {...field}
                    error={fieldState.error}
                    label="Work day"
                    display="block"
                  />
                )}
              />

              <Controller
                control={form.control}
                name={`desiredShiftTimesOfDay.${SchoolsJobApplicationDesiredShiftTimeOfDay.Evenings}`}
                render={({ field, fieldState }) => (
                  <CheckboxField
                    {...field}
                    error={fieldState.error}
                    label="Evenings"
                    display="block"
                  />
                )}
              />

              <Controller
                control={form.control}
                name={`desiredShiftTimesOfDay.${SchoolsJobApplicationDesiredShiftTimeOfDay.Nights}`}
                render={({ field, fieldState }) => (
                  <CheckboxField
                    {...field}
                    error={fieldState.error}
                    label="Nights"
                    display="block"
                  />
                )}
              />
            </div>

            <Typography
              variant="h3"
              color="textPrimary"
              css={(theme: AppTheme) => css`
                border-bottom: 1px solid ${theme.palette.divider};
                font-size: 1.2em;
                padding-bottom: ${theme.spacing(1)};
              `}
            >
              What days are you willing to work?
            </Typography>

            <div
              css={(theme: AppTheme) => css`
                padding: ${theme.spacing(2)};
              `}
            >
              <Controller
                control={form.control}
                name={`desiredShiftDaysOfWeek.${SchoolsJobApplicationDesiredShiftDaysOfWeek.MondayToFriday}`}
                render={({ field, fieldState }) => (
                  <CheckboxField
                    {...field}
                    error={fieldState.error}
                    label="Monday to friday"
                    display="block"
                  />
                )}
              />

              <Controller
                control={form.control}
                name={`desiredShiftDaysOfWeek.${SchoolsJobApplicationDesiredShiftDaysOfWeek.WeekendsOnly}`}
                render={({ field, fieldState }) => (
                  <CheckboxField
                    {...field}
                    error={fieldState.error}
                    label="Weekends"
                    display="block"
                  />
                )}
              />
            </div>
          </AppThemeProvider>

          <Button color="primary" size="large" label="Submit" type="submit" />
        </Form>
      </PageContent>
    </>
  );
};
