import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

import { createApolloClient } from "@rewards-web/shared/lib/apollo-client";
import { AppErrorBoundary } from "@rewards-web/shared/modules/error";
import { SnackbarProvider } from "@rewards-web/shared/modules/snackbar";
import { StyleProviders } from "@rewards-web/shared/style/global-styles";

import { SchoolsMvpAppRoutes } from "./pages/routes";
import {
  getAccessToken,
  isAuthenticated,
  SchoolsAuthProvider,
} from "./shared/modules/auth";
import { schoolsMvpTheme } from "./shared/theme";

const apolloClient = createApolloClient({
  graphQLURI: process.env.REACT_APP_GRAPHQL_URL!,
  appNameHeaderValue: "SCHOOLS_APP",
  isAuthenticated,
  getAccessToken: () => getAccessToken()!,
});

export const App = (): JSX.Element => {
  return (
    <StyleProviders theme={schoolsMvpTheme} renderCssBaseline>
      <ApolloProvider client={apolloClient}>
        <AppErrorBoundary>
          <BrowserRouter>
            <SnackbarProvider>
              <SchoolsAuthProvider>
                <SchoolsMvpAppRoutes />
              </SchoolsAuthProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </AppErrorBoundary>
      </ApolloProvider>
    </StyleProviders>
  );
};
