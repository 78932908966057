/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageContent } from "../../../shared/components/page-content";
import { PageHeader } from "../../../shared/components/page-header";
import { SurveyCard } from "../../../shared/components/survey-card";
import { useSchoolsHomePageDataQuery } from "./home-page-data.generated";

export const HomePage = () => {
  const homePageData = useSchoolsHomePageDataQuery({
    onError: reportError,
  });

  const content = (() => {
    if (homePageData.error) {
      return <ErrorPage />;
    }

    if (!homePageData.data) {
      return <PageLoadingState />;
    }

    const {
      data: {
        listSchoolsEmployerSurveys: { items },
      },
    } = homePageData;

    if (items.length === 0) {
      return (
        <Alert
          message="Check back later for survey results & more!"
          severity="info"
        />
      );
    }

    return items.map((item) => {
      return (
        <SurveyCard
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
          data={item}
          viewResultsLink={`/surveys/${item.id}`}
        />
      );
    });
  })();

  return (
    <>
      <PageHeader title="Home" />
      <PageContent>{content}</PageContent>
    </>
  );
};
