import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";

interface AlreadyAppliedToEmployerModalProps {
  open: boolean;
  onClose(): void;
}

export const AlreadyAppliedToEmployerModal = ({
  open,
  onClose,
}: AlreadyAppliedToEmployerModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>Already Applied</ModalTitle>
      <ModalContent>
        <Typography variant="body">
          You have already applied to this company.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button color="secondary" onClick={onClose} label="Okay" />
      </ModalActions>
    </Modal>
  );
};
