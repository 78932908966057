import { Route, Routes } from "react-router-dom";

import { Page } from "@rewards-web/shared/modules/page";

import { ApplyPage } from "./apply";
import { EmployerPage } from "./employer";
import { HomePage } from "./home";
import { SurveyResultsPage } from "./survey-results";

export const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <Page browserTitle="Home" analyticsPageName="Home">
            <HomePage />
          </Page>
        }
      />
      <Route
        path="/surveys/:id"
        element={
          <Page
            browserTitle="Survey Results"
            analyticsPageName="Survey Results"
          >
            <SurveyResultsPage />
          </Page>
        }
      />
      <Route
        path="/employers/:id"
        element={
          <Page
            browserTitle="Employer Profile"
            analyticsPageName="Employer Profile"
          >
            <EmployerPage />
          </Page>
        }
      />
      <Route
        path="/apply"
        element={
          <Page browserTitle="Apply" analyticsPageName="Apply">
            <ApplyPage />
          </Page>
        }
      />
    </Routes>
  );
};
