import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendExistingApplicationToEmployerMutationVariables = Types.Exact<{
  employerId: Types.Scalars['ID'];
}>;


export type SendExistingApplicationToEmployerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'applyToSchoolsEmployer'>
);


export const SendExistingApplicationToEmployerDocument = gql`
    mutation SendExistingApplicationToEmployer($employerId: ID!) {
  applyToSchoolsEmployer(employerId: $employerId)
}
    `;
export type SendExistingApplicationToEmployerMutationFn = Apollo.MutationFunction<SendExistingApplicationToEmployerMutation, SendExistingApplicationToEmployerMutationVariables>;

/**
 * __useSendExistingApplicationToEmployerMutation__
 *
 * To run a mutation, you first call `useSendExistingApplicationToEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendExistingApplicationToEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendExistingApplicationToEmployerMutation, { data, loading, error }] = useSendExistingApplicationToEmployerMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useSendExistingApplicationToEmployerMutation(baseOptions?: Apollo.MutationHookOptions<SendExistingApplicationToEmployerMutation, SendExistingApplicationToEmployerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendExistingApplicationToEmployerMutation, SendExistingApplicationToEmployerMutationVariables>(SendExistingApplicationToEmployerDocument, options);
      }
export type SendExistingApplicationToEmployerMutationHookResult = ReturnType<typeof useSendExistingApplicationToEmployerMutation>;
export type SendExistingApplicationToEmployerMutationResult = Apollo.MutationResult<SendExistingApplicationToEmployerMutation>;
export type SendExistingApplicationToEmployerMutationOptions = Apollo.BaseMutationOptions<SendExistingApplicationToEmployerMutation, SendExistingApplicationToEmployerMutationVariables>;