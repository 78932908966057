import { ApolloError } from "@apollo/client";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { DateField } from "@rewards-web/shared/components/date-field";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { PageContent } from "../../../shared/components/page-content";
import { PageHeader } from "../../../shared/components/page-header";
import { useSchoolsAuth } from "../../../shared/modules/auth";
import { useSignUpAsStudentMutation } from "./sign-up-as-student.generated";

interface SignUpPageFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  graduationDate: string | null;
}

export const SignUpPage = () => {
  const track = useTrack();
  const snackbar = useSnackbar();
  const { signedIn, onSignedIn } = useSchoolsAuth();
  const [signUpAsStudent] = useSignUpAsStudentMutation();

  const form = useForm<SignUpPageFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      graduationDate: null,
    },
  });

  const handleSubmit = async (values: SignUpPageFormValues) => {
    try {
      const res = await signUpAsStudent({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: serializePhoneNumber(values.phoneNumber),
          graduationDate: values.graduationDate!,
        },
      });

      const { accessToken } = res.data?.signUpAsSchoolsStudent!;

      if (!accessToken) {
        throw new Error("No access token in response");
      }

      onSignedIn(accessToken);
      track("Signed up");
    } catch (error) {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.message ===
          "Another student exists with phone number"
      ) {
        form.setError("phoneNumber", {
          message: "This phone number has already been used to sign up",
        });
      } else {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (signedIn) {
      // this navigation is trigged after the user is signed here
      // because we need to wait for the auth module (react context) to register
      // the login asynchronously
      navigate("/");
    }
  }, [signedIn, navigate]);

  return (
    <>
      <PageHeader title="Sign Up" />
      <PageContent>
        <Form
          onSubmit={form.handleSubmit(handleSubmit)}
          submitting={form.formState.isSubmitting}
        >
          <TextField
            {...form.register("firstName", {
              required: true,
            })}
            error={form.formState.errors.firstName}
            label="First Name"
          />
          <TextField
            {...form.register("lastName", {
              required: true,
            })}
            error={form.formState.errors.lastName}
            label="Last Name"
          />
          <TextField
            {...form.register("phoneNumber", {
              required: true,
            })}
            error={form.formState.errors.phoneNumber}
            label="Phone Number"
            type="tel"
          />
          <Controller
            control={form.control}
            name="graduationDate"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                error={fieldState.error}
                variant="month"
                label="Graduation date"
              />
            )}
          />
          <Button color="primary" type="submit" label="Sign Up" size="large" />
        </Form>
      </PageContent>
    </>
  );
};
