import { AppThemeProperties } from "@rewards-web/shared/style/theme";
import { DeepPartial } from "@rewards-web/shared/types";

export const fontFamily = ["Poppins", "sans-serif"].join(",");

export const schoolsMvpTheme: DeepPartial<AppThemeProperties> = {
  palette: {
    primary: {
      light: "rgb(112, 208, 167)",
      main: "#4DC591",
      dark: "rgb(53, 137, 101)",
    },
    secondary: {
      light: "rgb(92, 99, 188)",
      main: "#343DAC",
      dark: "rgb(36, 42, 120)",
      contrastText: "#fff",
    },
    text: {
      primary: "#000",
      secondary: "#7f8e9f",
    },
    background: {
      default: "#f2f6fc",
    },
    divider: "rgba(0,0,0,0.1)",
  },
  fonts: {
    main: fontFamily,
    headers: fontFamily,
  },
  typography: {
    body: {
      fontSize: "1.2em",
    },
  },
};
