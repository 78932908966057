/** @jsxImportSource @emotion/react */
import { Route, Routes, useLocation } from "react-router-dom";

import { useScrollToTop } from "@rewards-web/shared/hooks/use-scroll-to-top";
import { Page } from "@rewards-web/shared/modules/page";

import { PageLayout } from "../shared/components/page-layout";
import { useSchoolsAuth } from "../shared/modules/auth";
import { AuthenticatedRoutes } from "./authenticated/routes";
import { UnauthenticatedRoutes } from "./unauthenticated/routes";
import { SignUpPage } from "./unauthenticated/sign-up";

export const SchoolsMvpAppRoutes = (): JSX.Element => {
  const { signedIn } = useSchoolsAuth();

  // scroll to top on each location change
  const location = useLocation();
  useScrollToTop([location.pathname]);

  return (
    <PageLayout>
      <Routes>
        <Route
          path="/sign-up"
          element={
            <Page browserTitle="Sign Up" analyticsPageName="Sign Up">
              <SignUpPage />
            </Page>
          }
        />
        <Route
          path="*"
          element={
            signedIn ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />
          }
        />
      </Routes>
    </PageLayout>
  );
};
