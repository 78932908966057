import { Button } from "@rewards-web/shared/components/button";
import { FormControlContext } from "@rewards-web/shared/components/form/form-control";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useSendExistingApplicationToEmployerMutation } from "./send-existing-application-to-employer.generated";

interface ApplyToEmployerModalProps {
  open: boolean;
  onClose(): void;
  onApplied(): void;
  employerId: string;
}

export const ApplyToEmployerModal = ({
  open,
  onClose,
  onApplied,
  employerId,
}: ApplyToEmployerModalProps) => {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [
    apply,
    { loading: applying },
  ] = useSendExistingApplicationToEmployerMutation();

  return (
    <Modal open={open} onClose={onClose} disableBackdropClick={applying}>
      <FormControlContext.Provider value={{ submitting: applying }}>
        <ModalTitle>Confirm</ModalTitle>
        <ModalContent>
          <Typography variant="body">
            Your existing application will be sent to this employer.
          </Typography>
        </ModalContent>
        <ModalActions>
          <Button color="tertiary" onClick={onClose} label="Cancel" />
          <Button
            color="primary"
            label="Send Application"
            onClick={async () => {
              try {
                await apply({
                  variables: {
                    employerId,
                  },
                });
                snackbar.show({
                  severity: "success",
                  message: "You application has been sent!",
                });
                track("Applied to employer", { employerId });
                onClose();
                onApplied();
              } catch (error) {
                reportError(error);
                snackbar.show({
                  severity: "error",
                  message:
                    "An unexpected error occurred. Please try again later.",
                });
              }
            }}
          />
        </ModalActions>
      </FormControlContext.Provider>
    </Modal>
  );
};
