/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { darken } from "@material-ui/core";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";

interface PageLayoutProps {
  children: ReactNode;
}

export const PageLayout = ({ children }: PageLayoutProps): JSX.Element => {
  return (
    <div
      css={(theme: AppTheme) => css`
        position: relative;
        min-height: 100%;
        width: 100%;
        background-color: ${darken(theme.palette.background.default, 0.05)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          position: relative;
          min-height: 100%;
          width: 100%;
          max-width: 800px;
          background-color: ${theme.palette.background.default};
          margin: 0 auto;
        `}
      >
        {children}
      </div>
    </div>
  );
};
