import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SurveyResultsQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID'];
}>;


export type SurveyResultsQuery = (
  { __typename?: 'Query' }
  & { getSchoolsEmployerSurveyById?: Types.Maybe<(
    { __typename?: 'SchoolsEmployerSurvey' }
    & Pick<Types.SchoolsEmployerSurvey, 'id' | 'question'>
    & { results: Array<(
      { __typename?: 'SchoolsEmployerSurveyResult' }
      & Pick<Types.SchoolsEmployerSurveyResult, 'id'>
      & { employer: (
        { __typename?: 'SchoolsEmployer' }
        & Pick<Types.SchoolsEmployer, 'id' | 'name'>
      ), answers: Array<(
        { __typename?: 'SchoolsEmployerSurveyResultAnswer' }
        & Pick<Types.SchoolsEmployerSurveyResultAnswer, 'percentage'>
        & { answer: (
          { __typename?: 'SchoolsEmployerSurveyAnswer' }
          & Pick<Types.SchoolsEmployerSurveyAnswer, 'id' | 'answer'>
        ) }
      )> }
    )> }
  )> }
);


export const SurveyResultsDocument = gql`
    query SurveyResults($surveyId: ID!) {
  getSchoolsEmployerSurveyById(id: $surveyId) {
    id
    question
    results {
      id
      employer {
        id
        name
      }
      answers {
        percentage
        answer {
          id
          answer
        }
      }
    }
  }
}
    `;

/**
 * __useSurveyResultsQuery__
 *
 * To run a query within a React component, call `useSurveyResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyResultsQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveyResultsQuery(baseOptions: Apollo.QueryHookOptions<SurveyResultsQuery, SurveyResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyResultsQuery, SurveyResultsQueryVariables>(SurveyResultsDocument, options);
      }
export function useSurveyResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyResultsQuery, SurveyResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyResultsQuery, SurveyResultsQueryVariables>(SurveyResultsDocument, options);
        }
export type SurveyResultsQueryHookResult = ReturnType<typeof useSurveyResultsQuery>;
export type SurveyResultsLazyQueryHookResult = ReturnType<typeof useSurveyResultsLazyQuery>;
export type SurveyResultsQueryResult = Apollo.QueryResult<SurveyResultsQuery, SurveyResultsQueryVariables>;