/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { IconButton } from "@rewards-web/shared/components/icon-button";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface PageHeaderProps {
  title: string;
  backgroundColor?: "primary" | "secondary";
  image?: {
    url: string;
    alt: string;
  };
  description?: string;
  onBackClicked?(): void;
}

export const PageHeader = ({
  title,
  backgroundColor = "secondary",
  description,
  image,
  onBackClicked,
}: PageHeaderProps): JSX.Element => {
  return (
    <div
      css={(theme: AppTheme) => css`
        background-color: ${theme.palette[backgroundColor].main};
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: ${theme.spacing(2)};
      `}
    >
      {onBackClicked && (
        <IconButton
          css={css`
            position: absolute;
            top: 24px;
            left: 24px;
          `}
          aria-label="Go back"
          onClick={onBackClicked}
        >
          <ArrowBackIcon
            css={css`
              color: white;
              font-size: 1.2em;
            `}
          />
        </IconButton>
      )}

      {image && (
        <img
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
          src={image.url}
          alt={image.alt}
        />
      )}
      <Typography
        css={css`
          color: white;
          text-align: center;
        `}
        component="h1"
        variant="h2"
      >
        {title}
      </Typography>
      {description && (
        <Typography
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
            color: white;
            text-align: center;
          `}
          variant="body"
        >
          {description}
        </Typography>
      )}
    </div>
  );
};
