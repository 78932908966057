import { useEffect, useState } from "react";

import { useScrollToTop } from "@rewards-web/shared/hooks/use-scroll-to-top";
import { useTrack } from "@rewards-web/shared/modules/analytics";

import { ApplyBioPage } from "./steps/bio";
import { ApplyLocationPage } from "./steps/location";
import { ApplyWorkPage } from "./steps/work";
import {
  ApplyWizardContextProvider,
  ApplyWizardFormValues,
  ApplyWizardStep,
} from "./wizard-form-context";

export const ApplyPage = () => {
  const track = useTrack();
  const [formValues, setFormValues] = useState<ApplyWizardFormValues>({});
  const [currentStep, setCurrentStep] = useState<ApplyWizardStep>("bio");

  // scroll to the top when the current step changes
  useScrollToTop([currentStep]);

  useEffect(() => {
    track("Viewed apply step", { step: currentStep });
  }, [track, currentStep]);

  const currentPage = (() => {
    switch (currentStep) {
      case "bio":
        return <ApplyBioPage />;
      case "location":
        return <ApplyLocationPage />;
      case "work":
        return <ApplyWorkPage />;
    }
  })();

  return (
    <ApplyWizardContextProvider
      currentStep={currentStep}
      goToStep={setCurrentStep}
      formValues={formValues}
      updateFormValues={(values) =>
        setFormValues((prev) => ({ ...prev, ...values }))
      }
    >
      {currentPage}
    </ApplyWizardContextProvider>
  );
};
