/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Card, CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

import { SurveyResultProgress } from "./survey-result-progress";

interface SurveyResultCardProps extends StylableProps {
  data: {
    employer: {
      id: string;
      name: string;
    };
    answers: Array<{
      answer: {
        answer: string;
      };
      percentage: number;
    }>;
  };
}

export const SurveyResultCard = ({
  data,
  className,
}: SurveyResultCardProps) => {
  const linkToEmployer = `/employers/${data.employer.id}`;

  return (
    <Card className={className} variant="flat">
      <CardContent
        css={(theme: AppTheme) => css`
          display: flex;
          flex-direction: column;
          align-items: center;
          & > :nth-child(2) {
            margin-top: ${theme.spacing(1)};
          }

          ${theme.breakpoints.up("sm")} {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
          border-bottom: 1px solid ${theme.palette.divider};
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Link
            to={linkToEmployer}
            css={css`
              &:not(:hover) {
                text-decoration: none;
              }
            `}
          >
            <Typography color="secondary" variant="h3">
              {data.employer.name}
            </Typography>
          </Link>
        </div>
        <Button
          width="auto"
          color="secondary"
          size="small"
          label="View Employer"
          linkTo={linkToEmployer}
          endIcon={<ChevronRightIcon />}
        />
      </CardContent>
      <CardContent>
        <SurveyResultProgress
          results={data.answers.map(({ answer: { answer }, percentage }) => ({
            answer,
            percentage,
          }))}
        />
      </CardContent>
    </Card>
  );
};
