import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SignUpAsStudentMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  phoneNumber: Types.Scalars['String'];
  graduationDate: Types.Scalars['MonthAndYear'];
}>;


export type SignUpAsStudentMutation = (
  { __typename?: 'Mutation' }
  & { signUpAsSchoolsStudent: (
    { __typename?: 'SchoolsStudentLoginResponse' }
    & Pick<Types.SchoolsStudentLoginResponse, 'accessToken'>
  ) }
);


export const SignUpAsStudentDocument = gql`
    mutation SignUpAsStudent($firstName: String!, $lastName: String!, $phoneNumber: String!, $graduationDate: MonthAndYear!) {
  signUpAsSchoolsStudent(
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    graduationDate: $graduationDate
  ) {
    accessToken
  }
}
    `;
export type SignUpAsStudentMutationFn = Apollo.MutationFunction<SignUpAsStudentMutation, SignUpAsStudentMutationVariables>;

/**
 * __useSignUpAsStudentMutation__
 *
 * To run a mutation, you first call `useSignUpAsStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpAsStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpAsStudentMutation, { data, loading, error }] = useSignUpAsStudentMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      graduationDate: // value for 'graduationDate'
 *   },
 * });
 */
export function useSignUpAsStudentMutation(baseOptions?: Apollo.MutationHookOptions<SignUpAsStudentMutation, SignUpAsStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpAsStudentMutation, SignUpAsStudentMutationVariables>(SignUpAsStudentDocument, options);
      }
export type SignUpAsStudentMutationHookResult = ReturnType<typeof useSignUpAsStudentMutation>;
export type SignUpAsStudentMutationResult = Apollo.MutationResult<SignUpAsStudentMutation>;
export type SignUpAsStudentMutationOptions = Apollo.BaseMutationOptions<SignUpAsStudentMutation, SignUpAsStudentMutationVariables>;