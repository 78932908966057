/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

interface PageContentProps extends StylableProps {
  children: ReactNode;
}

export const PageContent = ({ children, className }: PageContentProps) => {
  return (
    <div
      className={className}
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(3)};
      `}
    >
      {children}
    </div>
  );
};
