/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { Button } from "@rewards-web/shared/components/button";
import {
  Card,
  CardActions,
  CardContent,
} from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { StylableProps } from "@rewards-web/shared/types";

import { SurveyIcon } from "./survey-icon";

export interface SurveyCardProps extends StylableProps {
  data: {
    id: string;
    question: string;
    results: Array<{
      id: string;
    }>;
  };
  viewResultsLink?: string;
}

export const SurveyCard = ({
  className,
  data,
  viewResultsLink,
}: SurveyCardProps): JSX.Element => {
  return (
    <Card className={className} variant="flat">
      <CardContent>
        <Typography
          css={(theme: AppTheme) => css`
            text-align: center;
            margin-bottom: ${theme.spacing(2)};
          `}
          color="textPrimary"
          variant="caption"
        >
          We asked PSWs at {data.results.length} North York Homecare Agencies
        </Typography>
        <div
          css={css`
            min-height: 50px;
            max-width: 300px;
            margin: 0 auto;
            display: flex;
            align-items: center;
          `}
        >
          <SurveyIcon
            css={(theme: AppTheme) => css`
              flex-grow: 1;
              height: 60px;
              width: 60px;
              margin-right: ${theme.spacing(1)};
            `}
          />
          <Typography
            css={css`
              font-weight: 600;
              font-size: 1.6em;
              text-align: center;
            `}
            color="textPrimary"
            variant="h3"
          >
            "{data.question}"
          </Typography>
        </div>
      </CardContent>
      {viewResultsLink && (
        <CardActions>
          <Button
            label="View results"
            endIcon={<ChevronRightIcon />}
            color="primary"
            linkTo={viewResultsLink}
          />
        </CardActions>
      )}
    </Card>
  );
};
