import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AuthenticateStudentLoginTokenMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String'];
  loginTokenId: Types.Scalars['String'];
  loginToken: Types.Scalars['String'];
}>;


export type AuthenticateStudentLoginTokenMutation = (
  { __typename?: 'Mutation' }
  & { authenticateSchoolsStudentLoginToken: (
    { __typename?: 'SchoolsStudentLoginResponse' }
    & Pick<Types.SchoolsStudentLoginResponse, 'accessToken'>
  ) }
);


export const AuthenticateStudentLoginTokenDocument = gql`
    mutation AuthenticateStudentLoginToken($phoneNumber: String!, $loginTokenId: String!, $loginToken: String!) {
  authenticateSchoolsStudentLoginToken(
    phoneNumber: $phoneNumber
    loginTokenId: $loginTokenId
    loginToken: $loginToken
    clientId: "SCHOOLS_APP"
  ) {
    accessToken
  }
}
    `;
export type AuthenticateStudentLoginTokenMutationFn = Apollo.MutationFunction<AuthenticateStudentLoginTokenMutation, AuthenticateStudentLoginTokenMutationVariables>;

/**
 * __useAuthenticateStudentLoginTokenMutation__
 *
 * To run a mutation, you first call `useAuthenticateStudentLoginTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateStudentLoginTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateStudentLoginTokenMutation, { data, loading, error }] = useAuthenticateStudentLoginTokenMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      loginTokenId: // value for 'loginTokenId'
 *      loginToken: // value for 'loginToken'
 *   },
 * });
 */
export function useAuthenticateStudentLoginTokenMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateStudentLoginTokenMutation, AuthenticateStudentLoginTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateStudentLoginTokenMutation, AuthenticateStudentLoginTokenMutationVariables>(AuthenticateStudentLoginTokenDocument, options);
      }
export type AuthenticateStudentLoginTokenMutationHookResult = ReturnType<typeof useAuthenticateStudentLoginTokenMutation>;
export type AuthenticateStudentLoginTokenMutationResult = Apollo.MutationResult<AuthenticateStudentLoginTokenMutation>;
export type AuthenticateStudentLoginTokenMutationOptions = Apollo.BaseMutationOptions<AuthenticateStudentLoginTokenMutation, AuthenticateStudentLoginTokenMutationVariables>;